
.ButtonSalvar{
    
    color: #5C4EC9;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    padding: 13px 35px;
    background-color: #80CAFF;
    border: 0px solid ;
    border-radius: 40px;
    font-weight: 800;
    cursor: pointer;
    }


    @media screen and (max-width: 430px) {

       
.ButtonSalvar{
    
    width: 100%;
    }

    }
