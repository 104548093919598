.progress-container {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
}

.progress-info{
    color: #000273;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
}

.progress-bar {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #000273;
    ;
    overflow: hidden;
    position: relative;
}

.progress {
    height: 100%;
    background-color: #75C5FF;
    color: white;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;
    transition: width 0.3s ease-in-out;
}

.complete {
    background-color: #3f51b5;
    /* Verde quando atingir 70% */
}

.progress-text {
    color: #000273;

    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 15.6px */
    margin-top: 10px;


}

.progress-title {
    color: #5C4EC9;
    text-align: left;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    line-height: 100%;
    /* 18px */
    margin-left: 10px;
    margin-bottom: 10px;
}