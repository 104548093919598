.fileInputContainer {
    display: flex;
    justify-content: space-between;

    color: currentColor;
    box-sizing: content-box;
    background: none;
    margin: 32px 0 2px 0;
    -webkit-tap-highlight-color: transparent;
    padding: 12px 14px;
    border-radius: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: relative;
}

.downloadContainer {
    display: flex;
    flex-direction: column; /* Agora os botões aparecem em coluna */
    gap: 10px; /* Espaçamento entre os elementos */
    width: 100%;
}

.downloadItem {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.fileButton {
    background-color: #6c5ce7;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
}

.noFilesContainer {
    text-align: center;
    width: 100%;
}


.fileInputContainer .fileLabel {
    color: #991A1A;

    /* Texto maior */
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    cursor: pointer;
}

.fileLabelTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #06243D;
    cursor: pointer;
    top: -10px;
    background-color: #fff;
    position: absolute;
}

.fileLabelTitle::after,
.fileLabelTitle::before {
    content: "  ";
    white-space: pre;
}


.fileInputContainer .fileButton {
    background-color: #5C4EC9;
    padding: 8px 30px;
    border-radius: 8px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

@media screen and (max-width: 430px) {

    .fileInputContainer .fileButton {

        padding: 12px 8px;
        font-size: 14px;
        margin: 10px 0px;

    }

    .fileInputContainer {
        display: flex;
        flex-direction: column;
    }

}


.fileInputContainer.error {



    border-color: #d32f2f;
}