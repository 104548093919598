.errorHelperText {
    color: #d32f2f;
    font-size: 12px;
    font-weight: 400;
    font-family: "Roboto", "Open Sans", sans-serif;
    margin: 6px 0 30px 0;
}

#controlled-radio-buttons-group {
    color: #000273;
    margin-right: 20px;
    /* Texto maior */
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    /* 34.2px */
}

.radioHome {

    color: #5C4EC9;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 190%;
    /* 30.4px */
    background-color: #E3F3FF;
    padding: 0 20px 0 5px;
    border-radius: 100px;
}

.radioHome.instituicao {
    background-color: #F7F6FF;
}

.regulamento {
    color: #000273;

    /* Texto */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
}

.css-1m9pwf3 {
    color: #5C4EC9 !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    color: #5C4EC9 !important;
}

.css-dmmspl-MuiFormGroup-root {
    flex-direction: row !important;
    margin-left: 12px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {

    border: none !important;
    border-radius: 20px !important;
    padding: 8px 12px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
    color: #000273 !important;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0px 12px !important;
}


.div-discoveries {
    border: 1px solid #75C5FF !important;
    border-radius: 20px !important;
    margin-bottom: 5px !important;
    margin-top: 10px !important;
}

// .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
//     padding-right: 540px;
// }

.css-ahj2mt-MuiTypography-root {

    color: #000273;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    /* 30.4px */
}

.css-i4bv87-MuiSvgIcon-root {
    fill: #000273 !important;

}





.termos {

    color: #000273;
    background-color: #000273;

}

.termosText {
    color: #000273;
    font-family: "Open Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 127%;
    /* 13.97px */

}

.divRadio {
    margin-top: -3px;
}

.divTermos {
    width: 60%;
}

.divEndereco {
    margin: 20px 0px;
}

.textEdital {
    margin: 30px 0 20px 0;
    color: #000273;
    /* Texto */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    margin-left: 12px;
}

.divConteudoCand{
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 32px;
    margin-bottom: 20px;
}

.candTextNumber{
    color: #5C4EC9;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 190%; /* 30.4px */
margin: 1rem 0rem;
}

.divIndic {
    width: auto;
    text-align: center;

    margin-bottom: 12px;
}

.nameInsti{
    width: 258px;
}

.regulamentoTexto {
    margin: 30px 0 20px 0;
    color: #000273;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
    margin-left: 12px;

    a {
        color: #5C4EC9;

        /* Texto */
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 190%;
        /* 30.4px */


    }

    

    @media screen and (max-width: 375px) {

        .css-dmmspl-MuiFormGroup-root {
            margin-left: 0px;
        }

    }


}