.resetPasswordContainer {
    display: flex;
    justify-content: flex-end;
}

.resetPassword {
    background: none;
    border: none;
    cursor: pointer;
    color: #5C4EC9;
font-family: "Open Sans";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 190%; /* 24.7px */
}

.textLoginOne {

    color: #000273;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%;
}

.textLogin {

    color: #000273;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-top: 15px;
}

.textInscrevase {

    color: #000273;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-left: 7px;
}

.btnLogin{
    cursor: pointer;
    color: #000273;
text-align: center;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

.btnInscrevase{
    cursor: pointer;
    color: #000273;
text-align: center;
font-family: "Open Sans";
font-size: 19px;
font-style: normal;
font-weight: 800;
line-height: normal;
}


.divEnd{

    margin-top: 20px;
    text-align: center;

}

.textInscrevaseTitle {
    color: #000273;
    text-align: center;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin: 25x !important;
}

.textRegisterIndic {
    color: #000273;
    font-family: "Open Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 118%;
}

.textLoginTitleCand{
    color: #000273;
text-align: center;
font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 800;
line-height: 190%; /* 38px */
margin: 20px 0px;
}

.MuiInputBase-input{
    
    padding: 12.5px 14px !important;
}

.textLoginTitle {
    color: #000273;
    text-align: center;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin: 25x !important;
}

@media screen and (max-width: 768px) {

    .textRegisterIndic{
        font-size: 16px;
    }

    .textLogin{
        font-size: 19px;
    }

}