.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFF;
  padding: 27px;
  border-radius: 4px;
  width: 679px;
  text-align: left;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
  position: relative;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #6D4DC1; // Roxo mais próximo do da imagem
  margin-bottom: 12px;
}

.alert-text {
  color: #5C4EC9;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
  line-height: 20px; /* 100% */
letter-spacing: 0.25px;
  margin-top: 6px;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.backButton {
  font-family: "Open Sans";
  color: #5C4EC9;
  background-color: #80CAFF;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  font-size: 18px;
  padding: 10px 40px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

.nextButton {
  font-family: "Open Sans";
  color: #F7F6FF;
  background-color: #5C4EC9;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  font-size: 18px;
  padding: 12px 40px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

