.section-one {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.div-Lattes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

}

.div-Recommendation {
    display: flex
;
    margin-bottom: 20px;
    /* width: 800px; */
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 20px;
    justify-content: space-between;

}

.btn-Download {
    padding: 12px 12px;
    color: #fff;
    background-color: #3498DB;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
    border: none;
}

.downloadItem {
    padding: 10px 0px;
    margin: 10px 0;
}

.fileName {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding: 0px 12px;
}

.btn-Download {
    padding: 8px 15px;
    font-size: 14px;
    font-weight: bold;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-decoration: none;
}

.btn-Download:hover {
    background-color: #0056b3;
}