.personalDataEditRow {
    display: flex;
    align-items: center;
    color: #000273;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 190%;
    margin-left: 4px !important;
    justify-content: space-between;

    strong {
        margin-right: 8px;
    }

    p {
        width: 413px;
        display: flex;
        align-items: center;
        color: #000273;
        font-family: "Open Sans";
        font-style: normal;
        line-height: 190%;
    }
}


@media screen and (max-width: 430px) {

    p {
        width: 413px;
    }

    .bgDivDados{
        padding: 1px 5px !important; 
    }
  
  }

  @media screen and (max-width: 768px) {

    p {
        width: 125px !important;
    }
  
  }


.bgDivDados{
    background-color: #E3F3FF;
    border-radius: 20px;
    padding: 1px 20px;
    margin: 10px 0px !important; 
}
.bgDivDados.instituicao{
    background-color: #EDEBFF;
}


.personalDataEditRow.edit {

    display: flex;
    margin: 0px 5px !important;
    justify-content: space-between;

    button {
        font-size: 10px !important;
        visibility: visible ;
        background-color: transparent !important;
        border-color: #5C4EC9 !important;
        color: #5C4EC9 !important;
    }

}